import React from "react";
import { Box, Flex, Spacer, Button, useMediaQuery, Select } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import { MdExitToApp } from "react-icons/md";
import useI18n from "../../hooks/useI18n";
import Logo from "./Logo";

const APP_URL = process.env.REACT_APP_APP_URL || 'https://app.enhance-your-ability.com';

const Header: React.FC = () => {
  const handleLoginClick = () => {
    const loginUrl = new URL(`${APP_URL}/auth/sign-in`);
    loginUrl.searchParams.set('lang', currentLanguage);
    window.location.href = loginUrl.toString();
  };

  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const location = useLocation();
  const ml = isMobileDevice ? "8vw" : "5vw";
  const { t, changeLanguage, currentLanguage } = useI18n("header");

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
    localStorage.setItem('landing_language_preference', newLanguage);
  };

  // Desktop menu items component
  const MenuItems = () => (
    <>
      <Box
        _hover={{ color: "#2b143b" }}
        color={location.pathname === "/contact-us" ? "#2b143b" : undefined}
      >
        <Link to="/contact-us">{t("contactUs")}</Link>
      </Box>
      <Box
        _hover={{ color: "#2b143b" }}
        color={location.pathname === "/faq" ? "#2b143b" : undefined}
      >
        <Link to="/faq">{t("faq")}</Link>
      </Box>
      <Box
        _hover={{ color: "#2b143b" }}
        color={location.pathname === "/blog" ? "#2b143b" : undefined}
      >
        <Link to="/blog">{t("blog")}</Link>
      </Box>
      <Button
        onClick={handleLoginClick}
        rightIcon={<MdExitToApp />}
        fontWeight="bold"
        color="rgba(41, 49, 28, 1)"
        cursor="pointer"
        background="none"
        border="none"
        fontSize={20}
        _hover={{ textDecoration: "underline" }}
      >
        {t("login")}
      </Button>
    </>
  );

  // Language selector component
  const LanguageSelector = ({ defaultValue, className }: { defaultValue: string, className?: string }) => (
    <Select
      value={currentLanguage}
      onChange={handleLanguageChange}
      width="auto"
      className={className}
    >
      <option value="uk">🇺🇦 UA</option>
      <option value="en">🇬🇧 EN</option>
    </Select>
  );

  return (
    <Flex
      as="header"
      alignItems="center"
      fontFamily="Poppins Polly, sans-serif"
    >
      <Box ml={ml}>
        <Logo size="110px" />
      </Box>

      <Spacer />

      {isMobileDevice ? (
        <Flex align="center" gap={8} fontSize={20} color="#2b143b8c" mr="7vw">
          <LanguageSelector defaultValue="uk" />
          <MenuItems />
        </Flex>
      ) : (
        <Flex>
          <LanguageSelector defaultValue="en" className="mr-2vw" />
          <Box mr="7vw">
            <BurgerMenu />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;